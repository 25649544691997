.App {
  text-align: center;
  margin: 2em 13%;
}

.hrTag {
  margin: 2em 13%;

  color: #a9a9a9;
  opacity: 0.6;
}

.form {
  padding: 1em 0em 2em 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upperText {
  margin-top: 3em;
}
.form label {
  padding-bottom: 1em;
}

.form button {
  margin-top: 2em;
}

.invalid {
  background-color: red;
}

.upperText {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.submitMUIbutton {
  margin: 1em;
  width: 12em;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

/* IMPROVEMENT: Use MUI themes instead */
.toggleLanguageButton {
  background-color: #a9a9a9 !important;
}
.continueButton {
  margin-top: 2em !important;
}

.testEmail {
  display: flex;
  align-items: flex-start !important;
}

.footer {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer div {
  margin: 0em 1em;
  position: relative;
  min-width: 12em;
  text-align: center;
}

.footer h4 {
  color: rgb(153, 153, 153);
  font-family: Oswald, sans-serif;
  /* font-size: 25px; */
  font-weight: 400;
  text-align: center;
  position: relative;
  margin-top: 4em;
}

.footer h6 {
  color: rgb(153, 153, 153);
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: 13px; */
  font-weight: 400;
  text-align: left;
  margin: 0.8em 0;
  text-align: center;
}

.footer p {
  color: rgb(153, 153, 153);
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: 13px; */
  font-weight: 400;
  text-align: left;
}

.iconCSS {
  color: rgb(51, 51, 51);
  cursor: pointer;
}

.Lowerfooter {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Lowerfooter h4 {
  color: rgb(153, 153, 153);
  font-family: Oswald, sans-serif;
  /* font-size: 25px; */
  font-weight: 400;
  text-align: center;
  position: relative;
  margin-top: 4em;
}

.Lowerfooter div {
  margin: 0em 5em;
}

.partner-logo {
  height: 65.5px;
  position: relative;
  display: flex;
  /* width: 100%; */
  width: 177.438px;

  margin-bottom: 1em;
}
.partner-logo1 {
  height: 65.5px;
  position: relative;
  display: block;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  /* width: 100px; */
}

.testt :hover {
  color: grey;
}
.testt {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.paymentStatus {
  margin: 2em 0;
  font-weight: bold;
}
